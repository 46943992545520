/*universal rule*/
*, *::before, *::after{
    box-sizing: border-box;
}

//preprocessing
@import "~bootstrap/scss/bootstrap";
@import './scss/variables.scss';
@import './scss/typography.scss';

body{
    margin: 0;
    background-color: var(--background);
    font-weight: 400;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    width: 100%;
}

#home{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5em;
}

#about{
    .content{
        .about-profile-image{
            width:70%;
            @media screen and (max-width: $browser-view){
                width: 100%;
            }    
        }
    }
}

@import './scss/header.scss';
@import './scss/components.scss';
