.content-container{
    display: grid;
    grid-template-areas:
        ". . ."
        ". content .";
    grid-template-columns: 15% auto 15%;
    grid-template-rows: 10vh auto;

    @media screen and (max-width: $browser-view){
        grid-template-columns: 5% auto 5%;
    }

    .content{
        grid-area: content;
    }
}

.link-button{  /*buttons for resume and certificate*/
    display: inline-flex;
    border: 2px solid;
    border-color: $white-font-color;
    border-radius: 3px;
    padding: 4px 5px 4px 5px;
    margin: 1px;
    text-decoration: none;
    color: $white-font-color;

    &:hover{
        color: $white-font-color;
        border-color: $white-font-color;
    }
}

.service{
    /*browser view*/
    width:100%;
    display: flex;
    align-items: flex-start;

    .service-name{
        max-width: 40%;
        min-width: 40%;
    }

    // .service-details{}

    /*if mobile or tablet view*/
    @media screen and (max-width: $browser-view){
        display: block;
        justify-content: center;

        .service-name{
            max-width: 100%;
            min-width: 100%;
            margin-right: 0;
        }

        .service-details{
            width: 100%;
        }
    }
}

.portfolio-card{
    position: block;
    width: 100%;
    margin: 1vw 1vh 1vw 1vh;

    .portfolio-card-container{
        display:flex;
        @media screen and (max-width: $browser-view){
            display: block;
        }
    }

    .portfolio-card-image {
        display: block;
        width: 40%;
        height: auto;
        margin-right: 1vw;

        @media screen and (max-width: $browser-view){
            display: block;
            width: 100%;
            margin-right: 0;
        }
    }

    .portfolio-card-text {
        width: auto;
        @media screen and (max-width: $browser-view){
            width: 100%;
        }
    }
}

.contact-form-input{
    display:block;
}
