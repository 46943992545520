body{
    color: $white-font-color;
    font-family: 'Work Sans', sans-serif;
    font-size: 1.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-family: 'Crimson Text', serif;
}
