/*navigation style header starts here*/
header{
    background: var(--background);
    text-align: center;
    position: fixed;
    z-index: 999;
    width: 100%;

    ul{
        list-style-type: none;
    }

    a{
        text-decoration: none;
    }

    .logo{
        a{
            color: $white-font-color;
        }
    }

    /*Styling for the browser view*/
    @media screen and (min-width: $browser-view){
        display: grid;
        grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;

        .logo{
            grid-column: 2 / 3;
        }
    }

    nav{
        background: var(--background);
        position: absolute;
        text-align: left;
        width: 100%;
        top: 100%;
        left: 0;
        transform: scale(1, 0);                   /*making it transfrom smoothly*/
        transform-origin: top;
        transition: transform 400ms ease-in-out;

        /*Styling for the browser view*/
        @media screen and (min-width: $browser-view){
            all: unset;
            grid-column: 3 / 4;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        ul{
            margin: 0;
            padding: 0;

            li{
                margin-bottom: 1em;
                margin-left: 1em;
            }

            /*Styling for the browser view*/
            @media screen and (min-width: $browser-view){
                display: flex;

                li{
                    margin-left: 3em;
                    margin-bottom: 0;
                }
            }
        }

        a{
            text-transform: uppercase;
            font-size: 1.2rem;
            color: $white-font-color;
            opacity: 0;
            transition: opacity 150ms ease-in-out;

            // &:hover{
            //     color: $white-font-color;
            // }

            /*Styling for the browser view*/
            @media screen and (min-width: $browser-view){
                opacity: 1;
                position: relative;

                /*adding neat animation effect on the browser view navlink*/
                &::before{
                    content: '';
                    display: block;
                    height: 5px;
                    background: $white-font-color;
                    position: absolute;
                    bottom: -.75em;
                    left: 0;
                    right: 0;
                    transform: scale(0, 1);
                    transform-origin: left;
                    transition: transform ease-in-out 250ms;
                }

                &:hover::before{
                    transform: scale(1, 1);
                }
            }
        }
    }

    .nav-toggle{
        display: none;

        &:checked ~ nav{
            transform: scale(1, 1);
        }

        &:checked ~ nav a{
            opacity: 1;
            transition: opacity 250ms ease-in-out 250ms;
        }
    }

    /*Creating a mobile nav bar control*/
    .nav-toggle-label{
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 1em;  /*lining up with ul in nav*/
        height: 100%;
        display: flex;
        align-items: center;

        /*making a hamburger symbol*/
        span, 
        span::before,
        span::after{
            display: block;
            background: $white-font-color;
            height: 2px;
            width: 2em;
            border-radius: 2px;
            position: relative;
        }

        /*repositioning hamburger symbol*/
        span::before,
        span::after{
            content: '';
            position: absolute;
        }

        span:before{
            bottom: 7px;
        }

        span::after{
            top: 7px;
        }

        /*Disabling the humburger control in the browser view*/
        @media screen and (min-width: $browser-view){
            display: none;
        }
    }
}
